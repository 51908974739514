import axios from 'axios'
import auth from '~/utils/auth'

const getUniqueID = () => {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  return s4() + s4() + '-' + s4();
}

const baseDomain = process.env.API_URL

const axiosDomain = axios.create({
  baseDomain
})

axiosDomain.interceptors.request.use(async (config) => {
  if (typeof window !== 'undefined') {
    await auth.refreshToken()
    .catch(e => {
      window.location.href = '/login'
    })
    config.headers.accesstoken = localStorage.idToken || getUniqueID()
  } else {
    config.headers.accesstoken = getUniqueID()
    config.headers.referer = process.env.DOMAIN_HREF
  }
  return config
}, error => {
  window.location.href = '/login'
})

export default {
  async getH2h(fixtureId, localteamId, visitorteamId) {
    const url = `${baseDomain}/api/v2/h2h/${fixtureId}?localteam_id=${localteamId}&visitorteam_id=${visitorteamId}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return axiosDomain.get(url, config)
  },

  async getStandings(seasonId) {
    const url = `${baseDomain}/v2/standings/${seasonId}`
    const config = {
      ...(process.client ? { // localStorage is not accessible on the server. Throws an error on league page
        headers: {
          "accesstoken": localStorage?.idToken || getUniqueID()
        }
      } : {})
    }
    return axiosDomain.get(url, config)
  },

  async getFixturesV2 (date, is_live, timezone, lastGames, page, sort, cancelToken, filters={}, limit=null, filterType) {
    let url = `${baseDomain}/api/v2/fixtures/${date}/?is_live=${is_live}&last_games=${lastGames}&filter_type=${filterType}&timezone=${timezone}&page=${page}&sort=${sort}`
    if (limit) {
      url += `&limit=${limit}`
    }
    const body = {
      'filters': filters
    }
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken
    }

    return axiosDomain.post(url, body, config)
  },

  async getFixtureMinuteStats (fixtureId) {
    const url = `${baseDomain}/api/v2/fixtures/${fixtureId}/minute-stats`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
    }

    return axiosDomain.get(url, config)
  },

  async getFixtureOddsByMinute (fixtureId, minute) {
    const url = `${baseDomain}/api/v2/fixtures/${fixtureId}/odds-by-minute/${minute}`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
    }

    return axiosDomain.get(url, config)
  },

  async backtestByFilters (data) {
    const url = `${baseDomain}/api/v2/backtesting`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }

    return axiosDomain.post(url, data, config)
  },

  async getSidebarSquad(id) {
    const url = `${baseDomain}/api/v2/squads/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return axiosDomain.get(url, config)
  },

  async getFixtureOdds (id) {
    const url = `${baseDomain}/api/odds/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return axiosDomain.get(url, config)
  },

  async getQuickStats(query) {
    const url = `${baseDomain}/v2/quick-stats/${query.date}?last_games=${query.last_games}&filter_type=${query.filter_type}&hide_cup=${query.hide_cup}&timezone=${query.timezone}&limit=${query.limit}&is_ft=${query.is_ft}`
    
    return axiosDomain.get(url)
  },
  
  async cancelSubscription (subscription_id) {
    const url = `${baseDomain}/subscription/cancel/${subscription_id}`
    
    return axiosDomain.post(url)
  },

  async getPortalSession (customer_id) {
    const url = `${baseDomain}/api/customers-session/${customer_id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }

    return axiosDomain.post(url, {}, config)
  },

  async getCheckoutSession (customer_id, price_id, coupon, customerId, currency, mode) {
    const url = `${baseDomain}/api/checkout/${customer_id}/${price_id}/${coupon}?customerId=${customerId}&currency=${currency || 'eur'}&mode=${mode || 'subscription'}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    
    return axiosDomain.post(url, {}, config)
  },

  async checkUser (id) {
    const url = `${baseDomain}/api/users/check/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    
    return axiosDomain.get(url, config)
  },

  async checkUserEmail (email, onesignalId) {
    const url = `${baseDomain}/api/users/check`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const data = {
      email,
      onesignalId
    }

    return axiosDomain.post(url, data, config)
  },

  async getLeague (id) {
    const url = `${baseDomain}/v2/leagues/${id}`
    const config = {
      ...(process.client ? { // localStorage is not accessible on the server
        headers: {
          "accesstoken": localStorage?.idToken || getUniqueID()
        }
      } : {})
    }

    return axiosDomain.get(url, config)
  },

  async getTeamsByLeagueId (leagueId) {
    const url = `${baseDomain}/api/v2/teams/league/${leagueId}`
    const config = {
      ...(process.client ? { // localStorage is not accessible on the server
        headers: {
          "accesstoken": localStorage?.idToken || getUniqueID()
        }
      } : {})
    }

    return axiosDomain.get(url, config)
  },

  async getTeam (id) {
    const url = `${baseDomain}/api/v2/teams/${id}`

    return  axiosDomain.get(url)
  },

  async getPlayer (id) {
    const url = `${baseDomain}/v2/players/${id}`

    return axios.get(url)
  }
}
// todo: quick-stats authenticated route
