import api from '~/utils/api'
import orderBy from 'lodash.orderby'

export const state = () => ({
  league: {
    name: '',
    country: {
      data: {
        name: ''
      }
    },
    stages: [],
    rounds: [],
    fixtures: []
  },
  teams: [],
  standings: []
})

export const mutations = {
  INITIATE_LEAGUE (state) {
    state.league = {
      name: '',
      country: {
        data: {
          name: ''
        }
      },
      stages: [],
      rounds: [],
      fixtures: []
    }
  },
  SET_LEAGUE(state, league) {
    state.league = league
  },
  SET_TEAMS(state, teams) {
    state.teams = teams 
  },
  SET_STANDINGS(state, standings) {
    state.standings = standings 
  }
}

export const actions = {
  async GET_LEAGUE ({ commit, state }, payload) {
    if (payload !== state.league.id) {
      commit('INITIATE_LEAGUE')
      const res = await api.getLeague(payload)
      res.data.stages = orderBy(res.data.stages, 'sort_order')
      res.data.fixtures = orderBy(res.data.fixtures, 'starting_at')
      commit('SET_LEAGUE', res.data)
    }
  },
  async GET_TEAMS ({ commit, state }, payload) {
    const res = await api.getTeamsByLeagueId(payload)

    res.data = orderBy(res.data, [( team ) => { return team?.stats?.l5?.xg?.total || ''}], 'desc')
    commit('SET_TEAMS', res.data)
  },
  async GET_STANDINGS_AND_TEAMS_STATS ({ commit, state }, payload) {
    try {
      const res = await api.getStandings(state.league.season_id)
      commit('SET_STANDINGS', orderBy(res.data.standings, 'sort_order'))
    } catch (e) {
      commit('SET_STANDINGS', [])
    }
  }
}
