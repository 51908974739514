import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _51dcf6bd = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _8db3fb4e = () => interopDefault(import('../pages/backtesting/index.vue' /* webpackChunkName: "pages/backtesting/index" */))
const _c850dcf0 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _3d1f0c2d = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _492bbdca = () => interopDefault(import('../pages/earn.vue' /* webpackChunkName: "pages/earn" */))
const _305d4adc = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _45ba3a31 = () => interopDefault(import('../pages/leagues.vue' /* webpackChunkName: "pages/leagues" */))
const _3319a0f4 = () => interopDefault(import('../pages/legal.vue' /* webpackChunkName: "pages/legal" */))
const _0301e436 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ab68a9e6 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _0a3331c0 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _02e81bd0 = () => interopDefault(import('../pages/more.vue' /* webpackChunkName: "pages/more" */))
const _093a2d35 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _100dd6cc = () => interopDefault(import('../pages/quick-stats.vue' /* webpackChunkName: "pages/quick-stats" */))
const _91257e34 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _a625f20c = () => interopDefault(import('../pages/tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _198c63d7 = () => interopDefault(import('../pages/updates.vue' /* webpackChunkName: "pages/updates" */))
const _48e36cfa = () => interopDefault(import('../pages/account/feeds/index.vue' /* webpackChunkName: "pages/account/feeds/index" */))
const _312724fc = () => interopDefault(import('../pages/account/subscription.vue' /* webpackChunkName: "pages/account/subscription" */))
const _26f2d046 = () => interopDefault(import('../pages/bo/stats-twitter.vue' /* webpackChunkName: "pages/bo/stats-twitter" */))
const _8057e7e8 = () => interopDefault(import('../pages/filters/explore.vue' /* webpackChunkName: "pages/filters/explore" */))
const _57359a44 = () => interopDefault(import('../pages/stats/btts.vue' /* webpackChunkName: "pages/stats/btts" */))
const _235b7c00 = () => interopDefault(import('../pages/stats/cards.vue' /* webpackChunkName: "pages/stats/cards" */))
const _c67c7eca = () => interopDefault(import('../pages/stats/corners.vue' /* webpackChunkName: "pages/stats/corners" */))
const _04c919c6 = () => interopDefault(import('../pages/stats/over05-fh-goals.vue' /* webpackChunkName: "pages/stats/over05-fh-goals" */))
const _6e407f9e = () => interopDefault(import('../pages/stats/over15-goals.vue' /* webpackChunkName: "pages/stats/over15-goals" */))
const _75f224fd = () => interopDefault(import('../pages/stats/over25-goals.vue' /* webpackChunkName: "pages/stats/over25-goals" */))
const _6093c8b6 = () => interopDefault(import('../pages/stats/xg/index.vue' /* webpackChunkName: "pages/stats/xg/index" */))
const _375b0c12 = () => interopDefault(import('../pages/account/feeds/add.vue' /* webpackChunkName: "pages/account/feeds/add" */))
const _96ece992 = () => interopDefault(import('../pages/stats/xg/leagues.vue' /* webpackChunkName: "pages/stats/xg/leagues" */))
const _3b02e2d8 = () => interopDefault(import('../pages/account/feeds/edit/_id.vue' /* webpackChunkName: "pages/account/feeds/edit/_id" */))
const _94efec02 = () => interopDefault(import('../pages/football/match/_match.vue' /* webpackChunkName: "pages/football/match/_match" */))
const _1fc17714 = () => interopDefault(import('../pages/filters/_id/index.vue' /* webpackChunkName: "pages/filters/_id/index" */))
const _299a5841 = () => interopDefault(import('../pages/fixture/_id.vue' /* webpackChunkName: "pages/fixture/_id" */))
const _cb0b42c0 = () => interopDefault(import('../pages/league/_id/index.vue' /* webpackChunkName: "pages/league/_id/index" */))
const _7b88e88f = () => interopDefault(import('../pages/player/_id.vue' /* webpackChunkName: "pages/player/_id" */))
const _7584f2cb = () => interopDefault(import('../pages/team/_id.vue' /* webpackChunkName: "pages/team/_id" */))
const _d86c04fe = () => interopDefault(import('../pages/league/_id/stats/xg.vue' /* webpackChunkName: "pages/league/_id/stats/xg" */))
const _395a411f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _51dcf6bd,
    name: "account"
  }, {
    path: "/backtesting",
    component: _8db3fb4e,
    name: "backtesting"
  }, {
    path: "/callback",
    component: _c850dcf0,
    name: "callback"
  }, {
    path: "/contact",
    component: _3d1f0c2d,
    name: "contact"
  }, {
    path: "/earn",
    component: _492bbdca,
    name: "earn"
  }, {
    path: "/forgot-password",
    component: _305d4adc,
    name: "forgot-password"
  }, {
    path: "/leagues",
    component: _45ba3a31,
    name: "leagues"
  }, {
    path: "/legal",
    component: _3319a0f4,
    name: "legal"
  }, {
    path: "/login",
    component: _0301e436,
    name: "login"
  }, {
    path: "/logout",
    component: _ab68a9e6,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _0a3331c0,
    name: "maintenance"
  }, {
    path: "/more",
    component: _02e81bd0,
    name: "more"
  }, {
    path: "/notifications",
    component: _093a2d35,
    name: "notifications"
  }, {
    path: "/quick-stats",
    component: _100dd6cc,
    name: "quick-stats"
  }, {
    path: "/register",
    component: _91257e34,
    name: "register"
  }, {
    path: "/tracking",
    component: _a625f20c,
    name: "tracking"
  }, {
    path: "/updates",
    component: _198c63d7,
    name: "updates"
  }, {
    path: "/account/feeds",
    component: _48e36cfa,
    name: "account-feeds"
  }, {
    path: "/account/subscription",
    component: _312724fc,
    name: "account-subscription"
  }, {
    path: "/bo/stats-twitter",
    component: _26f2d046,
    name: "bo-stats-twitter"
  }, {
    path: "/filters/explore",
    component: _8057e7e8,
    name: "filters-explore"
  }, {
    path: "/stats/btts",
    component: _57359a44,
    name: "stats-btts"
  }, {
    path: "/stats/cards",
    component: _235b7c00,
    name: "stats-cards"
  }, {
    path: "/stats/corners",
    component: _c67c7eca,
    name: "stats-corners"
  }, {
    path: "/stats/over05-fh-goals",
    component: _04c919c6,
    name: "stats-over05-fh-goals"
  }, {
    path: "/stats/over15-goals",
    component: _6e407f9e,
    name: "stats-over15-goals"
  }, {
    path: "/stats/over25-goals",
    component: _75f224fd,
    name: "stats-over25-goals"
  }, {
    path: "/stats/xg",
    component: _6093c8b6,
    name: "stats-xg"
  }, {
    path: "/account/feeds/add",
    component: _375b0c12,
    name: "account-feeds-add"
  }, {
    path: "/stats/xg/leagues",
    component: _96ece992,
    name: "stats-xg-leagues"
  }, {
    path: "/account/feeds/edit/:id?",
    component: _3b02e2d8,
    name: "account-feeds-edit-id"
  }, {
    path: "/football/match/:match?",
    component: _94efec02,
    name: "football-match-match"
  }, {
    path: "/filters/:id",
    component: _1fc17714,
    name: "filters-id"
  }, {
    path: "/fixture/:id?",
    component: _299a5841,
    name: "fixture-id"
  }, {
    path: "/league/:id",
    component: _cb0b42c0,
    name: "league-id"
  }, {
    path: "/player/:id?",
    component: _7b88e88f,
    name: "player-id"
  }, {
    path: "/team/:id?",
    component: _7584f2cb,
    name: "team-id"
  }, {
    path: "/league/:id?/stats/xg",
    component: _d86c04fe,
    name: "league-id-stats-xg"
  }, {
    path: "/",
    component: _395a411f,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
